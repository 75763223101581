.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  font-size: 4rem;

  &.open {
    transform: translateX(0);
  }

  i {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

@media (orientation: landscape) {
  .loading {
    transform: translateX(100%);
  }
}

@media (orientation: portrait) {
  .loading {
    transform: translateX(-100%);
  }
}
