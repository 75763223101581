$menu_width: 250px;
$transition_duration: 0.3s;

@import "./effects-l.scss";
@import "./effects-p.scss";
@import "../../css/variables";

/* content style */ /* Individual effects */
/* Effect 1: Slide in on top */ /* Effect 2: Reveal */ /* Effect 3: Push*/
/* Effect 4: Slide along */ /* Effect 5: Reverse slide out */
/* Effect 6: Rotate pusher */ /* Effect 7: 3D rotate in */ /* Effect 8: 3D rotate out */
/* Effect 9: Scale down pusher */ /* Effect 10: Scale up */
/* Effect 11: Scale and rotate pusher */ /* Effect 12: Open door */
/* Effect 13: Fall down */ /* Effect 14: Delayed 3D rotate */
.st-container {
  height: 100%;
  position: relative;
  overflow: hidden;
  //background: var(--background_color);
  background: url("#{$base_url}/images/bg.png");

  .st-pusher {
    height: 100%;
    position: relative;
    left: 0;
    z-index: 99;
    transition: all $transition_duration;
    //background: var(--background_color);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.2);
      content: "";
      opacity: 0;
      transition: opacity $transition_duration, width 0.1s $transition_duration,
        height 0.1s $transition_duration;
    }

    &.with-audio {
      padding-bottom: 10rem;
    }
  }

  .st-content {
    height: 100%;
    overflow-y: scroll;
    position: relative;
  }

  .st-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    visibility: hidden;
    width: $menu_width;
    height: 100%;
    transition: all $transition_duration;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      content: "";
      opacity: 1;
      transition: opacity $transition_duration;
    }
  }

  &.st-menu-open {
    .st-pusher {
      filter: blur(1px);

      &::after {
        width: 100%;
        height: 100%;
        opacity: 0.8;
        transition: opacity $transition_duration;
      }
    }

    .st-menu {
      &::after {
        width: 0;
        height: 0;
        opacity: 0;
        transition: opacity $transition_duration, width 0.1s $transition_duration,
          height 0.1s $transition_duration;
      }
    }

    .audio-player {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.header {
  color: white !important;
  text-shadow: 2px 2px 1px black;

  .title {
    color: white;
    text-shadow: 2px 2px 1px black;
  }
}

@media (orientation: portrait) {
  .st-container {
    .st-pusher {
      left: auto;
      right: 0;
    }

    .st-menu {
      left: auto;
      right: 0;
    }
  }
}
