@import "../../../../css/variables.scss";

.section-services {
  .services-list {
    background: center / cover no-repeat url("#{$base_url}/images/services-bg.jpg");
    padding: 2rem;

    .services-container {
      .service {
        background: rgba(255, 255, 255, 0.95);
        margin: 0 0 2rem;

        &-content {
          padding: 2rem;
        }

        &-title {
          color: var(--blue);
          font-family: var(--secondary_font);
          font-size: 2.3rem;
        }

        &-text {
          line-height: 2.5rem;

          p {
            margin-bottom: 1rem;
            font-weight: 300;

            &:last-child {
              margin: 0;
            }
          }
        }

        &-price {
          font-weight: 500 !important;
          color: var(--blue);
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .services-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      grid-auto-rows: auto;

      .service {
        margin: 0 !important;
        display: inline-block;
        width: 100%;
      }
    }
  }
}
