.waypoint {

    .animated {
        transition: all .5s;
        transform: translateY(50vh);
        opacity: 0;
    }

    &.animate {
        .animated {
            opacity: 1;
            transform: translateY(0);
        }
    }
}