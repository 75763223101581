.st-container {

    &.st-effect-1 {
        .st-menu {
            visibility: visible;
            transform: translate3d(-100%, 0, 0);

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-menu {
                visibility: visible;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-2 {
        .st-menu {
            z-index: 1;

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
            }
        }
    }

    &.st-effect-3 {
        .st-menu {
            transform: translate3d(-100%, 0, 0);

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
            }
        }
    }

    &.st-effect-4 {
        .st-menu {
            z-index: 1;
            transform: translate3d(-50%, 0, 0);

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-5 {
        .st-menu {
            z-index: 1;
            transform: translate3d(50%, 0, 0);
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-6 {
        perspective: 1500px;

        .st-pusher {
            transform-origin: 0% 50%;
            transform-style: preserve-3d;
        }

        .st-menu {
            transform: translate3d(-100%, 0, 0);
            transform-origin: 100% 50%;
            transform-style: preserve-3d;

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0) rotateY(-15deg);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(-100%, 0, 0) rotateY(15deg);
            }
        }
    }

    &.st-effect-7 {
        perspective: 1500px;
        perspective-origin: 0% 50%;

        .st-pusher {
            transform-style: preserve-3d;
        }

        .st-menu {
            transform: translate3d(-100%, 0, 0) rotateY(-90deg);
            transform-origin: 100% 50%;
            transform-style: preserve-3d;
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(-100%, 0, 0) rotateY(0deg);
            }
        }
    }

    &.st-effect-8 {
        perspective: 1500px;
        perspective-origin: 0% 50%;

        .st-pusher {
            transform-style: preserve-3d;
        }

        .st-menu {
            transform: translate3d(-100%, 0, 0) rotateY(90deg);
            transform-origin: 100% 50%;
            transform-style: preserve-3d;

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(-100%, 0, 0) rotateY(0deg);
            }
        }
    }

    &.st-effect-9 {
        perspective: 1500px;

        .st-pusher {
            transform-style: preserve-3d;
        }

        .st-menu {
            opacity: 1;
            transform: translate3d(-100%, 0, 0);

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d(0, 0, -$menu_width);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-10 {
        perspective: 1500px;
        perspective-origin: 0% 50%;

        .st-menu {
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, 0, -$menu_width);
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-11 {
        perspective: 1500px;

        .st-pusher {
            transform-style: preserve-3d;
        }

        .st-menu {
            opacity: 1;
            transform: translate3d(-100%, 0, 0);

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d(100px, 0, -600px) rotateY(-20deg);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(0, 0, 0);
            }
        }
    }


    &.st-effect-12 {
        perspective: 1500px;

        .st-pusher {
            transform-origin: 100% 50%;
            transform-style: preserve-3d;
        }

        .st-menu {
            opacity: 1;
            transform: translate3d(-100%, 0, 0);

            &::after {
                display: none;
            }
        }

        &.st-menu-open {
            .st-pusher {
                transform: rotateY(-10deg);
            }

            .st-menu {
                visibility: visible;
                transition: transform $transition_duration;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-13 {
        perspective: 1500px;
        perspective-origin: 0% 50%;

        .st-menu {
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, -100%, 0);
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition-timing-function: ease-in-out;
                transition-property: transform;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.st-effect-14 {
        perspective: 1500px;
        perspective-origin: 0% 50%;

        .st-pusher {
            transform-style: preserve-3d;
        }

        .st-menu {
            transform: translate3d(-100%, 0, 0) rotateY(90deg);
            transform-origin: 0% 50%;
            transform-style: preserve-3d;
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition-delay: 0.1s;
                transition-timing-function: ease-in-out;
                transition-property: transform;
                transform: translate3d(-100%, 0, 0) rotateY(0deg);
            }
        }
    }

    &.st-effect-15 {
        perspective: 1500px;
        perspective-origin: 0% 50%;

        .st-menu {
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, 100%, 0);
        }

        &.st-menu-open {
            .st-pusher {
                transform: translate3d($menu_width, 0, 0);
            }

            .st-menu {
                visibility: visible;
                transition-timing-function: ease-in-out;
                transition-property: transform;
                transform: translate3d(0, 0, 0);
            }
        }
    }


}