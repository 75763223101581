@media (orientation: portrait) {
    .st-container {

        &.st-effect-1 {
            .st-menu {
                transform: translate3d(100%, 0, 0);
            }

            &.st-menu-open {
                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-2 {
            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }
            }
        }

        &.st-effect-3 {
            .st-menu {
                transform: translate3d(100%, 0, 0);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }
            }
        }

        &.st-effect-4 {
            .st-menu {
                transform: translate3d(50%, 0, 0);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }

                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-5 {
            .st-menu {
                transform: translate3d(-50%, 0, 0);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }

                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-6 {
            .st-pusher {
                transform-origin: 100% 50%;
            }

            .st-menu {
                transform: translate3d(100%, 0, 0);
                transform-origin: 0% 50%;
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0) rotateY(15deg);
                }

                .st-menu {
                    transform: translate3d(100%, 0, 0) rotateY(-15deg);
                }
            }
        }

        &.st-effect-7 {
            perspective-origin: 100% 50%;

            .st-menu {
                transform: translate3d(100%, 0, 0) rotateY(90deg);
                transform-origin: 0% 50%;
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }

                .st-menu {
                    transform: translate3d(100%, 0, 0) rotateY(0deg);
                }
            }
        }

        &.st-effect-8 {
            perspective-origin: 100% 50%;

            .st-menu {
                transform: translate3d(100%, 0, 0) rotateY(-90deg);
                transform-origin: 0% 50%;
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }

                .st-menu {
                    transform: translate3d(100%, 0, 0) rotateY(0deg);
                }
            }
        }

        &.st-effect-9 {
            .st-menu {
                transform: translate3d(100%, 0, 0);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, -$menu_width);
                }

                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-10 {
            perspective-origin: 100% 50%;

            .st-menu {
                z-index: 1;
                opacity: 1;
                transform: translate3d(0, 0, -$menu_width);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }

                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-11 {
            .st-menu {
                transform: translate3d(100%, 0, 0);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-100px, 0, -600px) rotateY(20deg);
                }

                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-12 {
            .st-pusher {
                transform-origin: 0% 50%;
            }

            .st-menu {
                transform: translate3d(100%, 0, 0);
            }

            &.st-menu-open {
                .st-pusher {
                    transform: rotateY(10deg);
                }

                .st-menu {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        &.st-effect-13 {
            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }
            }
        }

        &.st-effect-14 {
            perspective-origin: 100% 50%;

            .st-menu {
                transform: translate3d(100%, 0, 0) rotateY(-90deg);
                transform-origin: 100% 50%;
            }

            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }

                .st-menu {
                    transform: translate3d(100%, 0, 0) rotateY(0deg);
                }
            }
        }

        &.st-effect-15 {
            &.st-menu-open {
                .st-pusher {
                    transform: translate3d(-$menu_width, 0, 0);
                }
            }
        }


    }
}