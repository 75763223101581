.section-decouvrir {
  .restau-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .restau {
    margin: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: var(--secondary_font);
    font-size: 1.8rem;

    a {
      background: rgba(255, 255, 255, 0.8);
      display: block;
      width: 100%;
      text-align: center;
      padding: 1rem 0;
    }
  }
}
