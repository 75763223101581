html {
  //--blue: #004861;
  --blue: rgba(13, 112, 162, 1);

  --blue: #178fe5;
  //--green: #808a67;
  //--green: #bdcc98;
  //--green: #b5e050;
  //--green: #bac372;
  //--green: #808a67;
  --green: #b8cc87;
  --red: #d52a4a;
  --yellow: #f0cc14;

  --default_font: "Poppins", sans-serif;
  //--secondary_font: "Roboto Slab", serif;
  --secondary_font: "Galada", cursive;

  --default_font_weight: 300;
  /*
  --primary_color: #77b81e;
  --danger_color: #f59393;
  */

  --primary_color: var(--blue);

  --background_color: white;

  --dark_bg_color: var(--primary_color);
  --dark_color: white;

  --menu_bg_color: var(--blue);
  --menu_color: white;
  --menu_hover_color: var(--yellow);
  --menu_border_color: rgba(211, 214, 44, 0.8);
  --menu_hover_bg_color: var(--primary_color);

  --header_text_shadow: 1px 1px 1px white;
}
