@import "../../../../css/variables";

.section-home {
  height: 100%;

  .overlay {
    height: 100%;
  }

  .home {
    &-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-header {
      display: flex;
      flex-direction: column;
      color: var(--blue);
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2rem;
    }

    &-title {
      font-family: var(--secondary_font);
      font-weight: 400;
      font-size: 7rem;
      color: #76c723;

      &__img {
        height: 5rem;
        margin-bottom: 2rem;
      }
    }

    &-subtitle {
      font-size: 2.2rem;
      margin: 4rem 0 0;
    }

    &-img {
      width: 100%;
      flex-grow: 1;
      background: center / cover no-repeat url("#{$base_url}/images/home-bg.jpg");
    }

    &-menu {
      padding: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  @media only screen and (min-width: 768px) {
    .home {
      &-menu {
        flex-direction: row;
      }

      &-header {
        padding: 4rem 2rem 2rem;
      }

      &-title {
        &__img {
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }
    }
  }
}
