.section-a-propos {
  .first {
    font-family: var(--secondary_font);
    letter-spacing: 0.2rem;
    font-size: 3rem;
    color: var(--blue);
  }

  .about {
    &__booking,
    &__social {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.25s;
      cursor: pointer;
      color: var(--blue);
      padding-top: 4rem;
      text-decoration: none;

      &-logo {
        border: 2px solid var(--blue);
        height: 5rem;
        width: 5rem;
        border-radius: 50% 0px 50% 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }

      &-text {
        margin-top: 1rem;
        font-size: 2rem;
      }

      &:hover {
        color: var(--green);
        .about__booking-logo {
          border-color: var(--green);
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    &__social {
      font-size: 5rem;
    }
  }

  @media only screen and (min-width: 768px) {
    .about {
      &__links {
        justify-content: space-evenly;
      }
    }
  }
}
