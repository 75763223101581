.menu {
  position: relative;
  z-index: 800;
  background: var(--menu_bg_color);

  //background: linear-gradient(to bottom, #000000 0%, #63b780 50%);
  //background: rgba(19, 199, 88, 0.95);

  .menu-close {
    position: absolute;
    z-index: 1;
    left: 0;
    font-size: 40px;
    width: 100%;
    line-height: 60px;
    cursor: pointer;
    transition: color 0.2s;
    color: white;

    &:hover {
      color: var(--menu_hover_color);
    }
  }

  nav {
    //height: 100%;
    font-size: 13px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 100%;
      display: flex;
      flex-direction: column;

      li {
        > a,
        > span {
          display: block;
          padding: 1em 1em 1em 1.2em;
          outline: none;
          box-shadow: inset 0 -1px var(--menu_border_color);
          color: var(--menu_color);
          text-transform: uppercase;
          //text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          letter-spacing: 1px;
          font-weight: 400;
          transition: background 0.2s, box-shadow 0.2s;
          text-align: right;
          text-decoration: none;

          i {
            padding: 0 10px 0 0;
          }

          &:hover {
            background: var(--menu_hover_bg_color);
            box-shadow: inset 0 -1px var(--menu_border_color);
            color: var(--menu_hover_color);
          }
        }

        &:first-child {
          > a,
          > span {
            box-shadow: inset 0 -1px var(--menu_border_color),
              inset 0 1px var(--menu_border_color);
          }
        }
      }
    }

    .langs {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      color: white;
      justify-content: center;
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
      padding: 10px 0;

      i {
        flex-grow: 1;
        padding: 18px 13px;
        font-size: 25px;
        align-self: left;
      }

      .lang {
        --lang_size: 55px;
        text-align: center;
        text-transform: uppercase;
        color: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        align-self: center;
        margin: 0 20px 0 0;
        cursor: pointer;
        width: var(--lang_size);
        height: var(--lang_size);
        line-height: var(--lang_size);
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
          background: var(--menu_hover_bg_color);
          box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
          color: var(--menu_hover_color);
        }

        &.selected {
          background: rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
          color: var(--menu_hover_color);
        }
      }
    }
  }
}

.menu-trigger {
  position: fixed;
  z-index: 900;
  width: 4rem;
  height: 4rem;
  transition: all 0.3s;
  cursor: pointer;
  border: 4px solid var(--primary_color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: var(--primary_color);
  font-size: 1.5rem;

  span {
    display: inline-block;
    background: var(--menu_trigger_background_color);
    color: var(--menu_trigger_color);
    transition: color 0.2s;
  }

  &:hover {
    span {
      color: var(--menu_trigger_hover_color);
    }
  }
}

@media (orientation: landscape) {
  .menu-trigger {
    top: 1rem;
    left: 1rem;

    &.open {
      transform: translateY(-150px);
    }
  }

  .menu {
    .menu-close {
      position: relative;
      text-align: right;
      padding: 0 10px 20px 0;
      line-height: normal;
    }

    nav {
      ul {
        li {
          i {
            float: right;
            line-height: 30px;
            margin: 0 0 0 15px;
          }
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .menu-trigger {
    bottom: 10px;
    right: 10px;
    //right: calc(50% - 20px);

    &.open {
      transform: translateY(150px);
    }
  }

  .menu {
    .menu-close {
      bottom: 0;
      text-align: center;
    }

    nav {
      position: absolute;
      left: 0;
      bottom: 60px;
      height: auto;
      width: 100%;

      ul {
        width: 100%;

        li {
          > a,
          > span {
            text-align: left;
          }
        }
      }
    }
  }
}
