.diapo {
  height: 30vh;
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-width: 1px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  //padding: 1rem;

  &--enlarged {
    height: 60vh;

    .diapo__img {
      display: inline-block;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 2rem;
    }
  }

  &__content {
    position: absolute;
    left: -2000px;
    top: 0;
    height: 100%;
    width: 50px;
    white-space: nowrap;
    transition: all 0.3s;
  }

  &__nav {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background: var(--blue);
    width: 3rem;
    height: 3rem;
    font-size: 1.3rem;

    & i {
      margin: 0;
    }

    &:hover {
      border-color: var(--yellow);
      color: var(--yellow);
    }

    &:disabled {
      opacity: 0.4;
      &:hover {
        border-color: white;
        color: white;
      }
    }
  }

  &__enlarge {
    position: absolute;
    color: white;
    right: 0;
    bottom: 0;
    font-size: 1.5rem;
    z-index: 1;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all 0.25s;

    width: 3rem;
    height: 3rem;
    background: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      position: relative;
      z-index: 1;
      margin: 0;
      transform: scale(-1, 1);
    }

    &:hover {
      color: var(--green);
    }
  }

  &__img {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 2rem;
  }
}
