/*
section {
  width: 100%;
  //background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  .overlay {
    width: 100%;

    p {
      text-align: justify;
      //margin: 0 0 15px;
    }
  }

  .section-block {
    display: flex;
    flex-direction: row;
    min-height: 30vh;
    margin-bottom: 6rem;
    background: #f9f9f9;
    border: 1px solid #eee;
    border-left: 0;
    border-right: 0;
    transition: all 0.25s;
    //transition-delay: 0.5s;
    transform-origin: right center;
    position: relative;
    //min-height: 50vw;

    &.hover {
      transform: scale(2);
      z-index: 1;
      min-height: 50vh;

      .section-block-img {
        cursor: zoom-out;
      }

      .section-block-show {
        opacity: 0;
      }
    }

    .section-block-text {
      width: 50%;
      padding: 2rem 6rem 2rem 6rem;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      font-weight: 300;
      color: #666;

      .section-block-text-title {
        //color: var(--blue);
        //font-family: var(--secondary_font);
        font-size: 3rem;
        margin-bottom: 4rem;
        font-weight: 500;
        color: black;
      }
    }

    .section-block-img {
      flex-grow: 1;
      position: relative;
      cursor: zoom-in;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .section-block-show {
      display: none;
      position: absolute;
      color: white;
      left: 0;
      bottom: 0;
      font-size: 2rem;
      //padding: 1rem;

      cursor: pointer;
      transition: all 0.25s;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6rem 0 0 6rem;
      border-color: transparent transparent transparent rgba(13, 112, 162, 0.7);

      i {
        position: relative;
        left: -5rem;
        bottom: 3.2rem;
        z-index: 1;
      }

      &:hover {
        color: var(--yellow);
      }
    }
  }
}

@media only handheld {
  section {
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 1300px) {
  section {
    .overlay {
      //padding: 20px 10%;
    }
  }
}

@media only screen and (max-width: 768px) {
  section {
    .overlay {
      //padding: 20px 5%;
    }
  }
}

*/

section {
  width: 100%;

  .section-block {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    transition: all 0.25s;
    transform-origin: right center;
    position: relative;
    padding: 0 0 3rem;

    &:after {
      position: absolute;
      content: "";
      border-bottom: 1px solid #ccc;
      width: 70%;
      bottom: 2rem;
      left: 15%;
    }

    &-title {
      padding: 2rem 2rem 0;
      text-align: center;
      font-size: 3rem;
      margin-bottom: 2rem;
      font-weight: 500;
      color: black;
      font-family: var(--secondary_font);
    }

    &-img {
      height: 30vh;
      flex-grow: 1;
      position: relative;
      cursor: zoom-in;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 2rem;
    }

    &-text {
      padding: 2rem 2rem 0;
      display: flex;
      flex-direction: column;
      font-weight: 300;
      color: #666;
    }
  }

  .section-block-show {
    display: none;
  }
}
