.footer {
  padding: 0 2rem;
  font-size: 1.5rem;
  background: var(--background_color);
  //position: relative;
  z-index: 100;

  .credits {
    display: inline-block;
    position: relative;
    top: 3px;
  }

  .version {
    float: right;
    font-size: 1.2rem;
    position: relative;
    top: 3px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    font-size: 1.3rem;
  }
}
