.modal {
  position: fixed;
  left: 0;
  top: 0 !important;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  transform: translateX(0);

  &.open {
    transform: translateX(0);
  }

  .modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 60%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-close {
      position: absolute;
      right: 2.3rem;
      top: 1.5rem;
      font-size: 3rem;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

@media (orientation: landscape) {
  .modal {
    transform: translateX(100%);
  }
}

@media (orientation: portrait) {
  .modal {
    transform: translateX(-100%);

    .modal-content {
      max-width: none;
    }
  }
}
