@import "css/theme.scss";
html,
body,
#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
}

body {
  font-family: var(--default_font);
  font-size: 1.6rem;
  line-height: 3rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h01,
.h02,
.h03,
.h04,
.h05,
.h06 {
  font-weight: var(--title_font_weight);
  font-style: normal;
  text-rendering: optimizeLegibility;
}

h1 {
  margin-bottom: 2.1rem;
  font-size: 3.1rem;
  line-height: 1.35;
  letter-spacing: -0.1rem;
}

h2 {
  margin-bottom: 2.1rem;
  font-size: 2.4rem;
  line-height: 1.25;
}

h3 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 2rem;
  line-height: 1.5;
}

h4 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 1.7rem;
  line-height: 1.76;
}

h5 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

h6 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.3rem;
  line-height: 1.85;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.h01 {
  margin-bottom: 2.1rem;
  font-size: 3.1rem;
  line-height: 1.35;
  letter-spacing: -0.1rem;
}

.h02 {
  margin-bottom: 2.1rem;
  font-size: 2.4rem;
  line-height: 1.25;
}

.h03 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 2rem;
  line-height: 1.5;
}

.h04 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 1.7rem;
  line-height: 1.76;
}

.h05 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.h06 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.3rem;
  line-height: 1.85;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

input,
.input {
  width: 100%;
  background: none;
  border: 0;
  transition: all 0.3s;
  position: relative;
  outline: none;
  padding: 0 0.2rem;

  font-size: 1.5rem;
  font-weight: 300;

  border-bottom: 2px solid var(--primary_color);
}

button,
.button {
  background: none;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  font-weight: 300;
  color: var(--primary_color);

  i {
    margin-right: 1rem;
  }

  &:hover {
    color: var(--green);
  }

  &.icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      margin: 0;
    }
  }
}

button.link,
.button.link,
a,
.link {
  text-decoration: underline;
  //color: var(--primary_color);
  color: var(--blue);
  outline: none;
  transition: all 0.25s;

  &:hover {
    color: var(--green);
    text-decoration: none;
  }
}

textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--primary_color);
  box-shadow: 0 0 1.5px 0 var(--primary_color);
  outline: none;
}

.title {
  font-size: 5rem;
  font-weight: 300;
  font-family: var(--secondary_font);
  padding: 3rem 0 0;
  //color: var(--green);
  letter-spacing: 0.4rem;
  //text-shadow: 1px 1px 1px #2b2b2b;
  color: var(--yellow);
  text-shadow: 1px 1px 1px #aaa;
  span {
    color: var(--blue);
    text-shadow: 1px 1px 1px #aaa;
  }
}

.highlight {
  // color: var(--blue);
  font-family: var(--secondary_font);
  font-size: 2rem;
  font-weight: 500;
  //letter-spacing: 0;
  //color: var(--yellow);
  color: #72b12e;
}

fieldset {
  border: none;
  //box-shadow: 0 0 0 0.5px grey;
  border-color: var(--primary_color);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);

  legend {
    padding: 0 1rem;
    text-transform: uppercase;
    font-weight: 500;
    background: white;
    color: var(--primary_color);
  }
}

.layout {
  color: var(--default_color);

  section {
    transition: all 0.3s;
    margin: 0;
    //background: red;
    //background: white;

    .title {
      text-align: center;
      padding: 8rem 0 2rem;
    }
  }
}

.lead {
  text-align: left;
  width: 100%;

  h2 {
    color: var(--primary_color);
  }

  p {
    margin-bottom: 2rem;
    text-align: justify;
  }
}

.diapo-item {
  --diapo_text_color: var(--green);
  margin-bottom: 4rem;
  height: 60vh;

  .diapo-item-content {
    padding: 0.5rem 0;
    background: rgba(0, 0, 0, 0.7);
    //color: #666;
    color: white;
    font-weight: 300;

    h2 {
      font-family: var(--secondary_font);
      letter-spacing: 0.3rem;
      font-size: 3rem;
      color: var(--yellow);
      //text-shadow: 1px 1px 1px #6d6c6c;
      //background: rgba(184, 204, 135, 0.9);
      padding: 1rem 2rem;
    }

    p {
      flex-grow: 1;
      padding: 0 2rem 1rem;
      //background: rgba(255, 255, 255, 0.9);
    }

    //background: rgba(255, 255, 255, 0.9);
    //padding: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .section {
    .button {
      width: 100%;
    }
  }
}

/*
.discover-content {
    background: rgba(0, 0, 0, 0.5);
    //background: rgba(255, 255, 255, 0.8);
    padding: 2rem;

    h2 {
      font-size: 3rem;
      letter-spacing: 0.2rem;
      color: var(--yellow);
      font-family: var(--secondary_font);
    }
  }
  */

/*
/

html,
body,
#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
}

body {
  font-family: var(--default_font);
  font-size: 1.6rem;
  line-height: 3rem;
}

.layout {
  color: var(--default_color);

  section {
    transition: all 0.3s;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h01,
.h02,
.h03,
.h04,
.h05,
.h06 {
  font-weight: var(--title_font_weight);
  font-style: normal;
  text-rendering: optimizeLegibility;
}

h1 {
  margin-bottom: 2.1rem;
  font-size: 3.1rem;
  line-height: 1.35;
  letter-spacing: -0.1rem;
}

h2 {
  margin-bottom: 2.1rem;
  font-size: 2.4rem;
  line-height: 1.25;
}

h3 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 2rem;
  line-height: 1.5;
}

h4 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 1.7rem;
  line-height: 1.76;
}

h5 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

h6 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.3rem;
  line-height: 1.85;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.h01 {
  margin-bottom: 2.1rem;
  font-size: 3.1rem;
  line-height: 1.35;
  letter-spacing: -0.1rem;
}

.h02 {
  margin-bottom: 2.1rem;
  font-size: 2.4rem;
  line-height: 1.25;
}

.h03 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 2rem;
  line-height: 1.5;
}

.h04 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.8rem;
  font-size: 1.7rem;
  line-height: 1.76;
}

.h05 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.h06 {
  margin-bottom: 2.1rem;
  margin-bottom: 1.2rem;
  font-size: 1.3rem;
  line-height: 1.85;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

button,
.button {
  margin-bottom: 1.2rem;
  display: inline-block;
  font-weight: var(--title_font_weight);
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding: 0 3rem;
  margin: 0 0.3rem 1.2rem 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  height: 4.8rem;
  line-height: 4.8rem;
  border-radius: 2px;
  border: 1px solid var(--button_border_color);
  text-shadow: 0 0 10px var(--button_shadow);
  background: var(--button_background) !important;
  color: var(--button_color);

  &:hover {
    background: rgba(var(--background_color), 0.2) !important;
    color: var(--hover_color);
    border-color: var(--hover_color);
  }

  &:hover {
    outline: 0;
  }

  &:focus {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  i {
    font-size: 2rem;
    display: inline-block;
    margin: 0 10px 0 0;
    position: relative;
    top: 3px;
  }
}

a,
button.link,
.button.link,
.link {
  height: auto;
  line-height: normal;
  background: none !important;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
  letter-spacing: 0.1rem;
  transition: color 0.25s;
  text-shadow: none;
  color: var(--primary_color);
  font-weight: 400;

  &:hover {
    color: #9fe046;
    text-decoration: none;
  }
}

.title {
  font-weight: var(--title_font_weight);
  font-size: 2.3rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  text-align: center;
  margin: 0 0 4rem;
  color: var(--title_color);
  text-shadow: 2px 2px 3px var(--home_text_shadow_color);

  &::before {
    display: block;
    position: relative;
    content: "";
    width: 3px;
    height: 2rem;
    background-color: var(--separator_color);
    margin: 0 auto 1.5rem;
  }
}

.lead {
  font-weight: var(--lead_font_weight);
  line-height: 1.5;
  margin: 0 auto 10rem;
  font-size: 2rem;

  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 200px;
    background-color: var(--separator_color);
    text-align: center;
    margin: 20px auto 0;
  }
}

.form {
  display: block;
  width: 90%;
  margin: 0 auto;

  .field {
    margin: 0 0 30px;

    label {
    }

    .input {
      padding: 10px;
      width: 100%;
      border: 1px solid var(--input_border_color);
      background: rgba(var(--background_color), 0.8);
      border-radius: 2px;

      &.textarea {
        min-height: 200px;
      }
    }
  }

  .button {
    width: 100%;
  }
}

.table {
  width: 100%;

  tr {
    th {
      border-bottom: 2px solid var(--border_color);
      padding: 1rem;
    }

    td {
      border-bottom: 1px solid var(--border_color);
      vertical-align: middle;
      text-align: center;
      padding: 1.5rem 1rem;
    }
  }
}

input,
textarea {
  width: 100%;
  border: 1px solid var(--border_color);
  padding: 1rem;
  border-radius: 2px;
}

@media only screen and (max-width: 1024px) {
  .lead {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 900px) {
  .lead {
    font-size: 2.6rem;
  }
}

@media only screen and (max-width: 768px) {
  .lead {
    font-size: 2.1rem;
  }
}

@media only screen and (max-width: 600px) {
  .lead {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .lead {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .lead {
    font-size: 1.8rem;
  }
}
*/
