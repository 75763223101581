.booking {
  &__iframe {
    border: 0;
    width: 100%;
    height: 1000px;
  }

  &__title {
    margin: 2rem 0 0;
    text-align: center;
    color: var(--blue);
  }
}

.booking-link {
  margin: 0 auto;
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
  padding: 1rem 0 2rem;
}
