.section-contact {
  .section-block {
    .section-block-text {
      padding-top: 0;
    }
  }

  .contact {
    &__details {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__link {
      margin-bottom: 2rem;

      i {
        margin-right: 1rem;
      }
    }

    &__map {
      height: 60vh;
    }
  }

  .field {
    margin-bottom: 3rem;
  }

  textarea.message {
    height: 20vh;
  }

  .submit {
    position: relative;
    border: 1px solid var(--primary_color);
    border-radius: 2px;
    transition: all 0.2s;
    width: 100%;
    height: 5rem;

    &:hover {
      border-color: var(--green);
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .button,
    .waiting,
    .done {
      position: absolute;
      left: 0;
      top: 0;
      line-height: var(--height);
      text-align: center;
      padding: 1rem;
      width: 100%;
    }

    .done {
        background: #82f9b3;
    }

    .waiting,
    .done {
      display: none;
    }

    .waiting:after {
      content: " .";
      animation: dots 1s steps(5, end) infinite;
      display: inline-block;
      position: relative;
      left: 5px;
      font-size: 4rem;
      line-height: 0px;
    }

    --p1: 10px;
    --p2: 20px;
    --start_color: rgba(255, 255, 255, 0);
    --end_color: var(--primary_color);

    @keyframes dots {
      0%,
      20% {
        color: var(--start_color);
        text-shadow: var(--p1) 0 0 var(--start_color), var(--p2) 0 0 var(--start_color);
      }

      40% {
        color: var(--end_color);
        text-shadow: var(--p1) 0 0 var(--start_color), var(--p2) 0 0 var(--start_color);
      }

      60% {
        text-shadow: var(--p1) 0 0 var(--end_color), var(--p2) 0 0 var(--start_color);
      }

      80%,
      100% {
        text-shadow: var(--p1) 0 0 var(--end_color), var(--p2) 0 0 var(--end_color);
      }
    }

    &.submit-loading {
      .button {
        display: none;
      }
      .waiting {
        display: block;
      }
    }

    &.sent {
      .button {
        display: none;
      }

      .waiting {
        display: none;
      }

      .done {
        display: block;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .contact {
      &__blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        grid-auto-rows: auto;
        margin-bottom: 2rem;

        .section-block {
          margin: 0 !important;
          width: 100%;
          border: 1px solid #ddd;
        }
      }

      &__details {
        .section-block-text {
          flex-grow: 1;
          justify-content: center;
        }
      }
    }
  }
}
